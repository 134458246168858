import {gql} from "@apollo/client/core/index.js"
export const GET_EVENTS = gql`query GetApiEvents($apiKey: String!, $location: String, $day_list: Iterable, $date: Iterable,  $pageSize: Int, $after: String) {
    apiEvents(
        apiKey: $apiKey
        first: $pageSize
        after: $after
        location: $location
        date: $date
        day_list: $day_list
        order: [{listings_day: "ASC", listings_items_timeFrom: "ASC"}]
    ) {
        pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage

        }
        totalCount
        edges {
            node {
                id
                uuid
                distance
                opening
                referTo {
                    id
                    description
                    pictures(first: 1, random: true, picture_isShowOnWidget: true) {
                        totalCount
                        edges {
                            node {
                                picture {
                                    photograph {
                                        firstname
                                        lastname

                                    }
                                    copyright
                                    file {
                                        thumbnailUrl
                                        thumbnailWebpUrl
                                        thumbnailXmUrl
                                        thumbnailXmWebpUrl
                                        contentUrl

                                    }

                                }

                            }

                        }

                    }
                    likes {
                        totalCount

                    }

                }
                id
                uuid
                isLinkedProfile
                title
                description
                tags {
                    edges {
                        node {
                            id
                            uuid
                            title
                            config
                            isMain
                        }

                    }

                }
                pictures(first: 1, random: true, picture_isShowOnWidget: true) {
                    totalCount
                    edges {
                        node {
                            picture {
                                photograph {
                                    firstname
                                    lastname

                                }
                                copyright
                                file {
                                    thumbnailUrl
                                    thumbnailWebpUrl
                                    thumbnailXmUrl
                                    thumbnailXmWebpUrl
                                    contentUrl

                                }

                            }

                        }

                    }

                }
                likes {
                    totalCount

                }
                organizer {
                    slug
                    title
                    isPublished

                }
                profiles {
                    edges {
                        node {
                            uuid
                            title
                            isPublished
                            slug

                        }

                    }

                }
                location {
                    id
                    uuid
                    title
                    slug
                    profile {
                        id
                        isPublished

                    }
                    latitude
                    longitude
                    reservationLink
                    isPublished
                    categories {
                        edges {
                            node {
                                uuid
                                title
                                config

                            }

                        }

                    }
                    pictures(first: 1) {
                        totalCount
                        edges {
                            node {
                                picture {
                                    photograph {
                                        firstname
                                        lastname

                                    }
                                    copyright
                                    file {
                                        thumbnailUrl
                                        thumbnailWebpUrl
                                        thumbnailXmUrl
                                        thumbnailXmWebpUrl
                                        contentUrl

                                    }

                                }

                            }

                        }

                    }
                    address {
                        street
                        housenumber
                        city {
                            city
                            code
                            uuid

                        }

                    }

                }

            }

        }

    }
    publicApiKey(key: $apiKey){
        locations{
            edges{
                node{
                    uuid
                    title
                }
            }
        }
    }
}`;
